/* eslint-disable no-trailing-spaces */
/* eslint-disable quote-props */

export default {
  69: {
    type: 'sex-act',
    id: '69',
    category: 'oral',
    tags: [
      'position',
      'oral',
      'foreplay',
    ],
    bgImg: 'https://thumbs.taxi69.com/taxi69_mobile/videos/7850/7850_panoramic.jpg',
    link: [],
    details: null,
    yourAction: {
      title: '69!',
    },
  },
  
  'have-sex-covered-in-oil': {
    type: 'sex-act',
    id: 'have-sex-covered-in-oil',
    category: 'fetish',
    tags: [
      'fetish',
      'atmosphere',
      'senses',
      'mood',
      'attire',
    ],
    details: null,
    yourAction: {
      title: 'Have sex covered in oil',
    },
    title: 'Have sex covered in oil',
  },
  
  'watch-other-couples-have-sex': {
    type: 'sex-act',
    id: 'watch-other-couples-have-sex',
    category: 'voyerism',
    tags: [
      'voyerism',
    ],
    details: null,
    yourAction: {
      title: 'watch other couples have sex (live)',
    },
  },
  
  'let-another-couple-watch-us-have-sex': {
    type: 'sex-act',
    id: 'let-another-couple-watch-us-have-sex',
    title: 'let another couple watch us have sex',
    category: 'exhibitionism',
    tags: [
      'exhibitionism',
    ],
    details: null,
    yourAction: {
      title: 'let another couple watch us have sex',
    },
  },
  
  'have-sex-monogamously-with-other-couples': {
    type: 'sex-act',
    id: 'have-sex-monogamously-with-other-couples',
    category: 'exhibitionism',
    tags: [
      'exhibitionism',
    ],
    details: null,
    yourAction: {
      title: "have sex monogamously with other couples (don't touch other couples)",
    },
  },
  
  'include-another-female-in-sex': {
    type: 'sex-act',
    id: 'include-another-female-in-sex',
    category: 'group-sex',
    tags: [
      'group-sex',
    ],
    details: null,
    yourAction: {
      title: 'include another female in sex',
    },
  },
  
  'include-another-male-in-sex': {
    type: 'sex-act',
    id: 'include-another-male-in-sex',
    category: 'group-sex',
    tags: [
      'group-sex',
    ],
    details: null,
    yourAction: {
      title: 'include another male in sex',
    },
  },
  
  'include-another-couple-in-sex': {
    type: 'sex-act',
    id: 'include-another-couple-in-sex',
    category: 'group-sex',
    tags: [
      'group-sex',
    ],
    details: null,
    yourAction: {
      title: 'include another couple in sex (swinging, full-swap)',
    },
  },
  
  'include-more-than-two-males-in-sex': {
    type: 'sex-act',
    id: 'include-more-than-two-males-in-sex',
    category: 'group-sex',
    tags: [
      'group-sex',
    ],
    details: null,
    yourAction: {
      title: 'include more than two males in sex',
    },
  },
  
  'include-more-than-two-females-in-sex': {
    type: 'sex-act',
    id: 'include-more-than-two-females-in-sex',
    category: 'group-sex',
    tags: [
      'group-sex',
    ],
    details: null,
    yourAction: {
      title: 'include more than two females in sex',
    },
  },
  
  'participate-in-an-large-orgy': {
    type: 'sex-act',
    id: 'participate-in-an-large-orgy',
    category: 'group-sex',
    tags: [
      'group-sex',
      'orgy',
    ],
    details: null,
    yourAction: {
      title: 'participate in an large orgy (more than 4 people)',
    },
  },
  
  'have-another-person-take-video': {
    type: 'sex-act',
    id: 'have-another-person-take-video',
    category: 'exhibitionism',
    tags: [
      'exhibitionism',
      'photo',
      'video',
    ],
    details: null,
    yourAction: {
      title: 'have another person take video/photos while we have sex',
    },
  },
  
  'let-a-group-of-people-watch-us-have-sex': {
    type: 'sex-act',
    id: 'let-a-group-of-people-watch-us-have-sex',
    category: 'exhibitionism',
    tags: [
      'exhibitionism',
    ],
    details: null,
    yourAction: {
      title: 'let a group of people watch us have sex',
    },
  },
  
  'let-a-female-friend-watch-us-have-sex': {
    type: 'sex-act',
    id: 'let-a-female-friend-watch-us-have-sex',
    category: 'exhibitionism',
    tags: [
      'exhibitionism',
      'female',
    ],
    details: null,
    yourAction: {
      title: 'let a female friend watch us have sex',
    },
  },
  
  'let-a-male-friend-watch-us-have-sex': {
    type: 'sex-act',
    id: 'let-a-male-friend-watch-us-have-sex',
    category: 'exhibitionism',
    tags: [
      'exhibitionism',
      'male',
    ],
    details: null,
    yourAction: {
      title: 'let a male friend watch us have sex',
    },
  },
  
  'include-another-female-in-sex-where-only-the-females-interact': {
    type: 'sex-act',
    id: 'include-another-female-in-sex-where-only-the-females-interact',
    category: 'group-sex',
    tags: [
      'group-sex',
      'ffm',
    ],
    details: null,
    yourAction: {
      title: 'include another female in sex where only the females interact (FFM)',
    },
  },
  
  'include-another-male-in-sex-where-only-the-males-interact': {
    type: 'sex-act',
    id: 'include-another-male-in-sex-where-only-the-males-interact',
    category: 'group-sex',
    tags: [
      'group-sex',
      'mmf',
    ],
    details: null,
    yourAction: {
      title: 'include another male in sex where only the males interact (MMF)',
    },
  },
  
  'have-sex-in-front-of-a-mirror': {
    type: 'sex-act',
    id: 'have-sex-in-front-of-a-mirror',
    category: 'props',
    tags: [
      'voyerism',
      'props',
    ],
    details: null,
    yourAction: {
      title: 'Have sex in front of a mirror',
    },
  },
  
  'watch-porn-together': {
    type: 'sex-act',
    id: 'watch-porn-together',
    category: 'seduction',
    tags: [
      'voyerism',
      'foreplay',
      'mood',
      'atmosphere',
    ],
    details: null,
    yourAction: {
      title: 'Watch porn together',
    },
  },
  
  'use-sex-furniture': {
    type: 'sex-act',
    id: 'use-sex-furniture',
    category: 'props',
    tags: [
      'toys',
      'props',
    ],
    details: null,
    title: 'use sex furniture',
  },
  
  'video-taping-acts': {
    type: 'sex-act',
    id: 'video-taping-acts',
    category: 'exhibitionism',
    tags: [
      'exhibitionism',
    ],
    bgImg: 'https://www.wrvo.org/sites/wrvo/files/styles/medium/public/201206/Video_Camera.jpg',
    link: [],
    details: null,
    title: 'video taping acts',
  },
  
  'get-a-couples-erotic-massage': {
    type: 'sex-act',
    id: 'get-a-couples-erotic-massage',
    category: 'cuckolding',
    tags: [
      'group-sex',
      'eroticism',
      'foreplay',
      'mood',
      'atmosphere',
    ],
    bgImg: 'http://pragueforadults.com/wp-content/uploads/2015/03/eroticmassage.jpg',
    link: [],
    details: null,
    yourAction: {
      title: 'get a couples erotic massage',
    },
  },
};
