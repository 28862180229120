<template>
  <SplashScreen class="is-fullscreen" v-if="!user && !isLobby" />
  <div
    v-else
    id="app"
    :class="classes"
  >
    <loading-line
      v-if="overlay"
      :message="overlay"
      :extraClasses="overlayClasses"
    />
    <NavBar
      :accountReady="accountReady"
      :connectedToFriend="connectedToFriend"
      :currentPersonName="currentPersonName"
      @switchUser="switchUser"
    />
    <router-view />
  </div>
</template>

<script setup>

import {
  computed,
  watch,
  toRefs,
} from 'vue';

import {
  useRoute,
} from 'vue-router';

// @ is an alias to /src
// ~ is an alias to /node_modules
import NavBar from '@@/NavBar/NavBar.vue';
import LoadingLine from '@@/LoadingLine/LoadingLine.vue';

import useUsersStore from '@/store/users';
import SplashScreen from '@@/SplashScreen/SplashScreen.vue';

// unpackage
const route = useRoute();
const usersStore = useUsersStore();

const {
  authenticate,
} = usersStore;

authenticate();
const isLobby = computed(() => route.meta?.lobby || false);

const {
  user,
  theme,
  wallpaper,
  accountReady,
  currentPersonString,
  names,
  connectedToFriend,
  overlay,
  overlayClasses,
} = toRefs(usersStore);

// io

// computed
const wallpaperClass = computed(() => {
  const themeName = wallpaper.value
      || (user.value ? user.value.wallpaper : '');

  return themeName || themeName !== ''
    ? `has-wallpaper-${themeName}`
    : '';
});

const classes = computed(() => [
  wallpaperClass.value,
]);

const currentPersonName = computed(() => {
  try {
    if (connectedToFriend.value) {
      return 'Your Settings';
    }
    let userName = '';

    if (currentPersonString.value === 'user') {
      userName = names.value[currentPersonString.value].toLowerCase() === 'you'
        ? 'Your'
        : names.value[currentPersonString.value];
    } else {
      userName = names.value[currentPersonString.value].toLowerCase() === 'your partner'
        ? 'Your partner\'s'
        : names.value[currentPersonString.value];
    }

    return `${userName} Settings`;
  } catch (err) {
    return 'Settings';
  }
});

// methods
const {
  switchUser,
} = useUsersStore();

const setTheme = (themeClassName = 'purple-peach') => {
  const $html = document.querySelector('html');
  $html.className = `is-${themeClassName}`;
};

const initTheme = async () => {
  await setTheme();
};

// watchers
watch(
  theme,
  (newVal) => {
    setTheme(newVal);
  },
);

watch(
  currentPersonString,
  async () => {
    setTheme(theme.value);
  },
);

initTheme();

</script>

<style lang="scss">
  @import '@/_scss/entry.scss';
</style>
