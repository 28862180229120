/* eslint-disable max-len */
const lobby = true;
const requiresAuth = true;

export default [
  // home
  {
    path: '/',
    name: 'home',
    component: () => import(
      /* webpackChunkName: "home" */
      '../views/user/GetStartedView.vue'
    ),
    meta: {
      title: 'Sexy time is fun',
      lobby,
    },
    props: true,
  },

  {
    path: '/get-started',
    name: 'get-started',
    component: () => import(
      /* webpackChunkName: "get-started" */
      '../views/user/GetStartedView.vue'
    ),
    meta: {
      title: 'Sexy time is fun',
      lobby,
    },
    props: true,
  },

  {
    path: '/login',
    name: 'login',
    component: () => import(
      /* webpackChunkName: "login" */
      '../views/user/LoginView.vue'
    ),
    meta: {
      title: 'Login',
      lobby,
    },
    props: true,
  },

  // on boarding
  {
    path: '/register',
    name: 'register',
    component: () => import(
      /* webpackChunkName: "register" */
      '../views/user/RegisterView.vue'
    ),
    meta: {
      title: 'Register',
      lobby,
    },
  },

  // settings
  {
    path: '/settings',
    name: 'settings',
    component: () => import(
      /* webpackChunkName: "settings" */
      '../views/user/SettingsView.vue'
    ),
    meta: {
      title: 'Your settings',
      requiresAuth,
    },
  },

  // about
  // {
  //   path: '/about',
  //   name: 'about',
  //   component: () => import(
  //     /* webpackChunkName: "about" */
  //     '../views/about/AboutView.vue'
  //   ),
  //   meta: {
  //     title: 'About',
  //
  //   },
  // },

  // about
  {
    path: '/support',
    name: 'support',
    component: () => import(
      /* webpackChunkName: "about" */
      '../views/about/SupportUs.vue'
    ),
    meta: {
      title: 'Support us',
    },
  },

  /**
   * The discovery section. This section contains the pages where users are exposed
   * to and rate sex acts and positions
   */

  // discovery
  {
    name: 'discover',
    path: '/discover',
    component: () => import(
      /* webpackChunkName: "discover" */
      '../views/discover/DiscoverView.vue'
    ),
    meta: {
      title: 'Discover sex acts',
      requiresAuth,
    },
  },

  // sex acts
  {
    name: 'sex-acts',
    path: '/discover/sex-acts',
    component: () => import(
      /* webpackChunkName: "list" */
      '../views/discover/SexActsView.vue'
    ),
    meta: {
      title: 'Sex acts',
      requiresAuth,
    },
  },

  {
    name: 'questionnaire',
    path: '/discover/questionnaire',
    component: () => import(
      /* webpackChunkName: "list" */
      '../views/discover/QuestionnaireView.vue'
    ),
    meta: {
      title: 'Questionnaire',
      requiresAuth,
    },
  },

  // positions
  // {
  //   name: 'sex-positions',
  //   path: '/discover/sex-positions',
  //   component: () => import(
  //     /* webpackChunkName: "sexualpositions" */
  //     '../views/discover/SexPositionsView.vue'
  //   ),
  //   meta: {
  //     title: 'Sex positions',
  //     requiresAuth,
  //   },
  // },

  // matches
  {
    name: 'matches',
    path: '/discover/matches',
    component: () => import(
      /* webpackChunkName: "matches" */
      '../views/discover/OurMatchesView.vue'
    ),
    meta: {
      title: 'Your matches',
      requiresAuth,
    },
  },

  /**
   * The acitivities section is where we take the user rated things and use them
   * to in various ways
   */

  // activities
  {
    name: 'fun',
    path: '/fun',
    component: () => import(
      /* webpackChunkName: "fun" */
      '../views/activities/ActivitiesView.vue'
    ),
    meta: {
      title: 'Do things with your matches',
      requiresAuth,
    },
  },

  // the 2 minute game
  {
    name: '2minutegame',
    path: '/fun/2-minute-game',
    component: () => import(
      /* webpackChunkName: "2-minute-game" */
      '../views/activities/2-minute-game.vue'
    ),
    meta: {
      title: 'The 2 Minute Game',
      requiresAuth,
    },
  },

  // shuffler
  {
    name: 'shuffler',
    path: '/fun/shuffler',
    component: () => import(
      /* webpackChunkName: "shuffler" */
      '../views/activities/ShufflerView.vue'
    ),
    meta: {
      title: 'shuffler',
      requiresAuth,
    },
  },

  // date builter
  {
    name: 'date-builder',
    path: '/fun/date-builder',
    component: () => import(
      /* webpackChunkName: "datebuilder" */
      '../views/activities/DateBuilderView.vue'
    ),
    meta: {
      title: 'date builder',
      requiresAuth,
    },
  },

  // coupons
  {
    name: 'coupons',
    path: '/fun/coupons',
    component: () => import(
      /* webpackChunkName: "coupons" */
      '../views/activities/CouponsView.vue'
    ),
    meta: {
      title: 'Coupons',
      requiresAuth,
    },
  },

  {
    name: 'my-coupons',
    path: '/fun/my-coupons',
    component: () => import(
      /* webpackChunkName: "coupons-for-me" */
      '../views/activities/CouponsForMe.vue'
    ),
    meta: {
      title: 'My Coupons',
      requiresAuth,
    },
  },

  {
    name: 'coupon-mgmt',
    path: '/fun/make-coupons',
    component: () => import(
      /* webpackChunkName: "coupons-mgmt" */
      '../views/activities/CouponMgmt.vue'
    ),
    meta: {
      title: 'Make Coupons',
      requiresAuth,
    },
  },

  // catch all
  {
    path: '/:pathMatch(.*)*',
    component: () => import(
      /* webpackChunkName: "about" */
      '../views/404View.vue'
    ),
  },
];
