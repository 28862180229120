import IMPLIED_CONSENT_LIST from '@/lists/implied-consent';
import YOU_THEM_LIST from '@/lists/you-them';
import PERSON_PERFERENCE from '@/lists/personal-preference';
// import SEX_POSITONS from '@/lists/sex-positions';

export default {
  ...YOU_THEM_LIST,
  ...IMPLIED_CONSENT_LIST,
  ...PERSON_PERFERENCE,
  // ...SEX_POSITONS,
};
