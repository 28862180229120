/* eslint-disable max-len */
const requiresAuth = true;

export const home = {
  label: 'Home',
  path: 'home',
};

export const discover = {
  label: 'Discover',
  path: 'discover',
  requiresAuth,
  submenu: [

    {
      label: 'Are you satisfied?',
      path: '/discover/questionnaire',
      description: `Use this questionnaire to rate your sex life, indentify places for improvement,
      and to share you thoughts and needs with your partner.`,
    },

    {
      label: 'Sex acts',
      path: '/discover/sex-acts',
      description: `View our list of over 100 sex acts to try.  Mark what you like,
      what you're willing to do with your partner - your answers
      will be used in our various games and activities.`,
    },

    // {
    //   id: 'sexPositions',
    //   label: 'Sex positions',
    //   path: '/discover/sex-positions',
    //   description: `Our list of over 200 sexual positions has something for everyone. Mark what you like and your answers
    //   will be used in our various games and activities.`,
    // },

    {
      label: 'Your matches',
      path: '/discover/matches',
      description: `Have you and your partner checked out our sex positions and acts? See your matches here
      and find something you both want to do.`,
    },
  ],
};

export const activities = {
  label: 'Fun',
  path: 'fun',
  requiresAuth,
  submenu: [
    {
      label: 'The 2 Minute Game',
      path: '/fun/2-minute-game',
    },

    {
      label: 'Something Random',
      path: '/fun/shuffler',
    },

    {
      label: 'Date builder',
      path: '/fun/date-builder',
    },

    {
      label: 'Coupons',
      path: '/fun/coupons',
    },
  ],
};

// export const about = {
//   label: 'About',
//   path: 'about',
// };

export const support = {
  label: 'Support Us',
  path: 'support',
};

export const links = [
  home,
  // support,
];

export default links;
