import { createApp } from 'vue';
import { createPinia } from 'pinia';
import FontAwesomeIcon from '@/utilities/font-awesome';

import App from './App.vue';
import router from './router';

const pinia = createPinia();
const app = createApp(App);

app.use(pinia);
app.use(router);
app.component('font-awesome-icon', FontAwesomeIcon);
app.mount('#app-mount');
