export function getAccountObj() {
  return JSON.parse(localStorage.getItem('account') || '{}');
}

export function accountObjSavedLocally() {
  const a = getAccountObj();
  if (!a || !a.idCode) {
    return false;
  }

  return true;
}

export function setAccountObj(account) {
  localStorage.setItem('account', JSON.stringify(account));
}

export function setThemeObj(themeName) {
  localStorage.setItem('theme', themeName || 'purple-peach');
}

export function getThemeObj() {
  return localStorage.getItem('theme') || null;
}

export function setWallpaperObj(wallpaper) {
  localStorage.setItem('wallpaper', wallpaper || '');
}

export function getWallpaperObj() {
  return localStorage.getItem('wallpaper') || '';
}

export function deleteLocalObj(key) {
  localStorage.removeItem(key);
}

export default {
  accountObjSavedLocally,
  getAccountObj,
  setAccountObj,
  setThemeObj,
  getThemeObj,
  setWallpaperObj,
  getWallpaperObj,
  deleteLocalObj,
};
